import Cookies from 'js-cookie';

import React, { useState } from 'react';
//import '../login.css'; // Import CSS for styling
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap'; // Import React Bootstrap components

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = (e) => {
        e.preventDefault();
        // Add your authentication logic here
        console.log('Email:', email);
        console.log('Password:', password);

        if (email.toLowerCase() === "hamusingh@gmail.com".toLowerCase() && password === "aiishere24")
        {
            Cookies.set('daus', `${email}:${password}`, {
                expires: 7, // Set expiration if needed
                secure: false, // Only set the cookie over HTTPS
                sameSite: 'strict', // Helps to prevent CSRF attacks
            });
            
            document.location.href="/"
        }
        else
        {
            alert("Invalid Username/Password");
        }
    };

    return (
        <Container 
            fluid 
            className="d-flex justify-content-center align-items-center vh-100 bg-light" 
        >
            <Row className="w-100 justify-content-center">
                <Col md={6} lg={4}>
                    <Card className="p-5 shadow-lg">
                        <Card.Body>
                            <h2 className="text-center mb-4">Login</h2>
                            <Form onSubmit={handleLogin}>
                                <Form.Group className="mb-3" controlId="formEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        size="lg"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4" controlId="formPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Enter your password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                        size="lg"
                                    />
                                </Form.Group>

                                <Button variant="primary" type="submit" className="w-100" size="lg">
                                    Login
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;
