// usePageTracking.js
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';

const AuthTracker = () => {
    console.log("RUNNING....")

    useEffect(() => {
        // Read the cookie using js-cookie
        const value = Cookies.get('daus')

        if (value === undefined) {
            console.log("Cookie is not present")
            document.location.href="/login"
        }
        else {
            console.log("Cookie is present")
        }

    }, []);
};

export default AuthTracker;
