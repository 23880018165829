import { useState } from 'react';
import SearchBox from './search';
import AuthTracker from './authTracker';
import axios from 'axios'

function Analyze() {
    AuthTracker()
    const [searchTerm, setSearchTerm] = useState('');

    async function onSearchClick() {
        const postData = {
            input: searchTerm
        };
        
        // test
        const response = await fetch('/api/texttosql', {
            method: 'POST', // Specify the request method
            headers: {
              'Content-Type': 'application/json' // Set the content type to JSON
            },
            body: JSON.stringify(postData) // Convert the data to JSON
        });
        
        console.log(await response.text())
    }

    return (
        <div>
            <h1>Analyze</h1>
            <label htmlFor="source" className="select-label">Select source</label>
            <select id="source" className="styled-select">
                <option value="">--Select--</option>
                <option value="sqlServer">SQL Server</option>
                <option value="mySQL">My SQL</option>
                <option value="snowFlake">SnowFlake</option>
                <option value="bigQuery">BigQuery</option>
            </select>
            <br /> <br />
            <label htmlFor="search" className="select-label">What do you want to know?</label>
            <SearchBox id="search" setSearchTerm={setSearchTerm} placeholder="Type to search..." />
            <button className='styled-button' onClick={onSearchClick}>Search</button>
        </div>
    );
}

export default Analyze;