import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Analyze from './components/analyze';
import Login from './components/login';
import Home from  './components/home'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie';

function App() {
  // Read the cookie using js-cookie
  const value = Cookies.get('daus')

  if (value === undefined) {    
    return (    
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/analyze" element={<Analyze />} />
          <Route path="/alerting" element={<Services />} />
          <Route path="/vault" element={<Contact />} />
        </Routes>
        {/* <div>          
          <LoginPage />
        </div>         */}
      </Router>
    );
  }
  else {
    return (    
      <Router>
        <div className="app-container">
          <Header />
          <div className="main-container">
            <Menu />
            <Content />
          </div>
        </div>
      </Router>
    );
  }
}

function Header() {
  return (
    <div className="header">
      <h1 className="app-name">DeepAnalyze.ai</h1>
    </div>
  );
}

function Menu() {
  return (
    <div className="menu">
      <ul>
        <li><Link to="/home">Home</Link></li>
        <li><Link to="/analyze">Analyze</Link></li>
        <li><Link to="/alerting">Alerting</Link></li>
        <li><Link to="/vault">Vault</Link></li>
        <li><Link to="/login">Login</Link></li>
      </ul>
    </div>
  );
}

function LoginPage() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

function Content() {
  return (
    <div className="content">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/analyze" element={<Analyze />} />
        <Route path="/alerting" element={<Services />} />
        <Route path="/vault" element={<Contact />} />
      </Routes>
    </div>
  );
}


function Services() {
  return <h1>Our Services</h1>;
}

function Contact() {
  return <h1>Contact Us</h1>;
}

export default App;
