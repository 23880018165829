import React from 'react';
//import './SearchBox.css'; // Ensure to include the updated CSS

const SearchBox = ({ placeholder, setSearchTerm }) => {

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <form className="search-box">
      <textarea
        onChange={handleChange}
        className="search-input"
        placeholder={placeholder || "Type your search here..."}
        rows={4} // Specify the number of visible rows
      />
    </form>
  );
};

export default SearchBox;
